import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import useOnlinePlayersStore from '../../stores/onlinePlayersStore';
import { toRefs } from 'vue';
import { BIconCircleFill, BIconRobot } from 'bootstrap-icons-vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppOnlineStatus',
    props: {
        player: {
            type: Object,
            required: true,
        },
    },
    setup(__props) {
        const props = __props;
        const { player } = toRefs(props);
        return (_ctx, _cache) => {
            return (!_unref(player).isBot)
                ? (_openBlock(), _createBlock(_unref(BIconCircleFill), {
                    key: 0,
                    class: _normalizeClass(["online-status-icon", _unref(useOnlinePlayersStore)().isPlayerOnline(_unref(player).publicId) ? 'text-success' : 'text-secondary']),
                    "aria-hidden": "true"
                }, null, 8, ["class"]))
                : (_openBlock(), _createBlock(_unref(BIconRobot), {
                    key: 1,
                    class: "me-1 text-success",
                    "aria-hidden": "true"
                }));
        };
    }
});

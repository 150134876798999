import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "fst-italic"
};
const _hoisted_2 = { class: "text-body-secondary ms-2 d-inline-block" };
import { toRefs } from 'vue';
import { RouterLink } from 'vue-router';
import AppOnlineStatus from './AppOnlineStatus.vue';
import { createInitialRating, isRatingConfident } from '../../../shared/app/ratingUtils';
export default /*@__PURE__*/ _defineComponent({
    __name: 'AppPseudo',
    props: {
        player: {
            type: Object,
            required: true,
        },
        is: {
            type: String,
            default: 'span',
        },
        classes: {
            type: [String, Array, Object],
            default: null,
        },
        /**
         * Whether to display a green/grey circle on username to show player presence/absence
         */
        onlineStatus: {
            type: Boolean,
            default: false,
        },
        /**
         * How to display rating:
         * - `<AppPseudo />` If not set, rating is not shown.
         * - `<AppPseudo rating />` rating is shown in minimalist form, like "1500" or "~1500" depending on confidence
         * - `<AppPseudo rating="full" />` rating is fully shown, like "1500 ±140"
         */
        rating: {
            type: [Boolean, String],
            default: false,
        },
    },
    setup(__props) {
        const props = __props;
        const { player, is, classes, onlineStatus, rating } = toRefs(props);
        const { round } = Math;
        const currentRating = () => { var _a; return (_a = player.value.currentRating) !== null && _a !== void 0 ? _a : createInitialRating(player.value); };
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                (_unref(onlineStatus))
                    ? (_openBlock(), _createBlock(AppOnlineStatus, {
                        key: 0,
                        player: _unref(player)
                    }, null, 8, ["player"]))
                    : _createCommentVNode("", true),
                _createVNode(_unref(RouterLink), {
                    to: _unref(player).slug ? { name: 'player', params: { slug: _unref(player).slug } } : '',
                    class: "text-body text-decoration-none"
                }, {
                    default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(is)), {
                            class: _normalizeClass(_unref(classes))
                        }, {
                            default: _withCtx(() => [
                                (_unref(player).isGuest)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('guest')) + " ", 1))
                                    : _createCommentVNode("", true),
                                _createElementVNode("span", null, _toDisplayString(_unref(player).pseudo), 1)
                            ]),
                            _: 1
                        }, 8, ["class"])),
                        (_unref(rating))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "small" }, " ", -1)),
                                _createElementVNode("small", _hoisted_2, [
                                    ('full' === _unref(rating))
                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                            _createTextVNode(_toDisplayString(_unref(round)(currentRating().rating)) + " ±" + _toDisplayString(_unref(round)(currentRating().deviation * 2)), 1)
                                        ], 64))
                                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                            (!_unref(isRatingConfident)(currentRating()))
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    _createTextVNode("~")
                                                ], 64))
                                                : _createCommentVNode("", true),
                                            _createTextVNode(_toDisplayString(_unref(round)(currentRating().rating)), 1)
                                        ], 64))
                                ])
                            ], 64))
                            : _createCommentVNode("", true)
                    ]),
                    _: 1
                }, 8, ["to"])
            ], 64));
        };
    }
});

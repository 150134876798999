export class RichChat {
    constructor(hostedGame) {
        this.richChatMessages = [];
        this.generators = [
            new MoveNumberHeader(hostedGame),
            new DateHeader(hostedGame),
        ];
        for (const chatMessage of hostedGame.chatMessages) {
            this.postChatMessage(chatMessage);
        }
    }
    getRichChatMessages() {
        return this.richChatMessages;
    }
    postChatMessage(chatMessage) {
        for (const generator of this.generators) {
            this.richChatMessages.push(...generator.yieldChatHeaders(chatMessage));
        }
        this.richChatMessages.push(chatMessage);
    }
}
export const createRichChat = (hostedGame) => {
    const richChat = new RichChat(hostedGame);
    return richChat.getRichChatMessages();
};
class AbstractChatHeaderGenerator {
    constructor(hostedGame) {
        this.hostedGame = hostedGame;
        this.init();
    }
    init() { }
}
/**
 * Add date header when a message is posted on a new day from previous one.
 * Note that "new day" is relative to timezone,
 * so date headers won't be at same places on differents timezones.
 */
class DateHeader extends AbstractChatHeaderGenerator {
    init() {
        var _a, _b;
        this.lastDate = this.toLocalDate((_b = (_a = this.hostedGame.gameData) === null || _a === void 0 ? void 0 : _a.startedAt) !== null && _b !== void 0 ? _b : this.hostedGame.createdAt);
    }
    yieldChatHeaders(chatMessage) {
        const currentDate = this.toLocalDate(chatMessage.createdAt);
        if (currentDate === this.lastDate) {
            return [];
        }
        this.lastDate = currentDate;
        return [
            { type: 'date', date: new Date(currentDate) },
        ];
    }
    /**
     * Returns date of a js date, i.e "2024-08-24",
     * in current timezone.
     */
    toLocalDate(date) {
        return date.toLocaleDateString('sv');
    }
}
/**
 * Add move number header, move is already played when messages below are posted.
 */
class MoveNumberHeader extends AbstractChatHeaderGenerator {
    constructor() {
        super(...arguments);
        this.lastMoveNumber = 0;
    }
    yieldChatHeaders(chatMessage) {
        if (null === this.hostedGame.gameData) {
            return [];
        }
        const { movesHistory } = this.hostedGame.gameData;
        let currentMoveNumber = this.lastMoveNumber;
        while (movesHistory.length > currentMoveNumber
            && chatMessage.createdAt >= movesHistory[currentMoveNumber].playedAt) {
            ++currentMoveNumber;
        }
        if (currentMoveNumber === this.lastMoveNumber) {
            return [];
        }
        this.lastMoveNumber = currentMoveNumber;
        return [
            { type: 'move', moveNumber: currentMoveNumber },
        ];
    }
}
